<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog" width="500">
      <v-card class="pa-2">
        <v-card-title class="font-weight-bold">
          <span style="color: #000000">{{ title }}</span>
        </v-card-title>
        <v-form ref="form" class="mt-5 px-16 list-item">
          <div>
            <span>{{ $t("facility.productsForSale.saleItemName") }}</span>
            <v-text-field
              v-if="isEdit"
              :value="currentSaleItem.name"
              hide-details
              dense
              readonly
            />
            <v-select
              v-else
              v-model="saleItemSelected"
              :items="saleItemList"
              :rules="rules.name"
              item-text="name"
              item-value="id"
              dense
              @change="currentSaleItem.id = saleItemSelected"
            />
          </div>
          <div class="mt-4">
            <span>{{ $t("facility.productsForSale.saleUnitPrice") }}{{ currentSaleItem.priceIncludeTax ? '（税込）' : '（税抜）' }}</span>
            <tvos-int-input v-model="currentSaleItem.price" jpy required class="mb-9" />
          </div>
          <div class="mt-4">
            <span>{{
              $t("facility.productsForSale.changePriceAccommodation")
            }}</span>
            <v-radio-group
              hide-details
              v-model="currentSaleItem.allowPriceOverwrite"
              row
            >
              <v-radio class="radio" label="変更不可" :value="false"></v-radio>
              <v-radio class="radio ml-10" label="変更可能" :value="true"></v-radio>
            </v-radio-group>
          </div>
        </v-form>
        <v-card-text class="mt-10">
          <v-row>
            <v-col :cols="6">
              <v-btn
                v-if="isEdit"
                color="var(--bt__red)"
                class="white--text"
                @click="handleSubmit('delete')"
              >
                {{ $t("buttons.delete") }}
              </v-btn>
            </v-col>
            <v-col :cols="6" class="d-flex justify-end pb-3">
              <v-btn
                color="var(--bt__red)"
                class="white--text"
                @click="closeDialog"
              >
                {{ $t("buttons.cancel") }}
              </v-btn>
              <v-btn
                color="var(--bt__blue)"
                class="white--text ml-2"
                @click="handleSubmit"
              >
                {{ $t("buttons.saveAndCreate") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SALE_ITEM_LIST } from '@/api/graphql/facility/facility-sale-item'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { MAX_INT } from '@/constants/enum'
import gql from 'graphql-tag'

export default {
  name: 'createNewSaleItem',

  props: {
    visible: Boolean,
    title: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      saleItemList: [],
      saleItemSelected: null,
      rules: {
        name: [
          () =>
            this.currentSaleItem.id !== null ||
            this.$t('facility.productsForSale.itemIsRequired')
        ],
        price: [
          v => this.checkNull(v) || this.$t('rules.isRequired'),
          v => this.checkCurrent(v) || this.$t('rules.isInvalid')
        ]
      }
    }
  },

  watch: {
    getStatusUpdateFacilitySaleItem () {
      this.getSaleItemList()
    }
  },

  computed: {
    ...mapGetters(['getStatusUpdateFacilitySaleItem']),

    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    ...mapGetters(['currentSaleItem']),
    formattedAmount: {
      get () {
        return Number(this.currentSaleItem.price).toLocaleString('ja-JP')
      },
      set (value) {
        this.value = value
        this.value = this.value.replace(/,/g, '')
        this.value = this.value.replace(/[a-zA-Z]+/ig, '')
        this.currentSaleItem.price = this.value
      }
    }
  },

  mounted () {
    this.getSaleItemList()
  },

  methods: {
    ...mapActions(['updateFacilitySaleItem']),
    ...mapMutations(['resetSaleItem', 'setErrorDialog']),
    handleSubmit (check) {
      if (this.$refs.form.validate()) {
        if (check === 'delete') {
          this.updateFacilitySaleItem('delete').then(this.closeDialog)
        } else if (this.isEdit) {
          this.updateFacilitySaleItem('edit').then(this.closeDialog)
        } else {
          this.updateFacilitySaleItem('create').then(this.closeDialog)
        }
      }
    },

    async getSaleItemList () {
      await this.$apollo.query({
        query: gql`${SALE_ITEM_LIST}`,
        variables: {
          includeNotForSale: true
        }
      }).then((data) => {
        this.saleItemList = data.data.saleItemList
      }).catch((error) => {
        console.error(error)
      })
    },

    checkCurrent (v) {
      if (v !== null && v < MAX_INT && v >= 0) return true
      else return false
    },

    checkNull (v) {
      if (v !== null) return true
      else return false
    },

    closeDialog () {
      this.$refs.form.resetValidation()
      this.$emit('close')
      this.saleItemSelected = null
    }
  }
}
</script>

<style scoped lang="scss">
.list-item::v-deep {
  font-size: 12px;
  span {
    color: #000000;
    font-weight: bold;
    font-size: 10px !important;
  }
  .v-select__selection {
    color: #000000;
    font-size: 14px !important;
    font-weight: 500;
  }
  input {
    color: #000000;
    font-size: 14px !important;
    font-weight: 500;
  }
  .v-label {
    color: #444444;
    font-size: 14px !important;
  }
  .price-input {
    .v-input__prepend-inner {
      width: 18px !important;
    }
    i {
      font-size: var(--font-size__normal);
      margin-top: 3px;
      font-size: 14px !important;
    }
  }
}
::v-deep {
  .radio {
    span {
      color:#757575
    }
  }
  .v-btn__content {
    font-size: 12px;
  }
  .v-card__title{
    font-size: 22px !important;
  }
  .v-btn {
    .v-btn__content{
      font-size: 14px !important;
    }
  }
}
</style>
