<template>
  <BaseFacility>
  <template v-slot:title>
      <h1 class="py-4 page-title-main">
      {{ $t("routeTitles.facility.facility") }}
    </h1>
  </template>
    <template v-slot:default>
      <v-card class="pa-5 mt-10">
        <v-card-title>
          {{ $t("facility.productsForSale.listOfItemForSale") }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="header"
            :items="saleItemList"
            hide-default-footer
            disable-sort
            disable-pagination
            :no-data-text="$t('rules.noData')"
            class="table-custom"
          >
            <template v-slot:[`item.allowPriceOverwrite`]="{ item }">
              {{ item.allowPriceOverwrite ? "変更可能" : "変更不可" }}
            </template>
            <template v-slot:[`item.tax`]="{ item }">
              {{ item.taxType === 'Normal' ? '消費税 10%' : item.taxType === 'Lowered' ? '軽減税率 8%' : '不課税' }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ item.price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) }}
              {{ item.priceIncludeTax ? '（税込）' : '（税抜）' }}
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                :disabled="!checkPerUser"
                outlined
                color="var(--bt__blue)"
                @click="
                  setSaleItem(item);
                  isShowEdit = true;
                "
              >
                {{ $t("buttons.edit") }}
              </v-btn>
            </template>
          </v-data-table>
          <v-row class="mt-10 d-flex justify-end pb-8">
            <v-btn
              :disabled="!checkPerUser"
              color="var(--bt__blue)"
              class="white--text"
              @click="createNewItem()"
            >
              {{ $t("facility.productsForSale.createNewSaleItem") }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <CreateNewSaleItem
        :key="idx"
        :visible="isShowCreateNew"
        :title="$t('facility.productsForSale.createNewSaleItem')"
        :is-edit="false"
        @close="isShowCreateNew = false"
      />
      <CreateNewSaleItem
        :visible="isShowEdit"
        :title="$t('facility.productsForSale.editSaleItem')"
        :is-edit="true"
        @close="isShowEdit = false"
      />
    </template>
  </BaseFacility>
</template>

<script>
import BaseFacility from '@/components/Facility/BaseFacility'
import CreateNewSaleItem from './createNewSaleItem'
import { formatCurrency } from '@/constants/functions'
import { SALE_ITEM_LIST } from '@/api/graphql/facility/facility-sale-item'
import { mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'ProductsForSale',
  apollo: {
    saleItemList: {
      query: gql`${SALE_ITEM_LIST}`,
      fetchPolicy: 'no-cache'
    }
  },

  computed: {
    ...mapGetters(['currentSaleItem', 'getStatusUpdateFacilitySaleItem'])
  },

  methods: {
    formatCurrency,
    ...mapMutations(['setSaleItem', 'setErrorDialog']),

    createNewItem () {
      this.isShowCreateNew = true
      this.idx = this.idx + 1
      this.currentSaleItem.price = 0
      this.currentSaleItem.id = null
    }
  },
  data () {
    return {
      idx: 0,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      isShowCreateNew: false,
      isShowEdit: false,
      header: [
        { text: this.$t('facility.productsForSale.saleItemID'), value: 'id' },
        {
          text: this.$t('facility.productsForSale.saleItemName'),
          value: 'name'
        },
        {
          text: this.$t('facility.productsForSale.saleUnitPrice'),
          value: 'price'
        },
        { text: this.$t('facility.productsForSale.taxRate'), value: 'tax' },
        {
          text: this.$t('facility.productsForSale.chargeOnStatement'),
          value: 'allowPriceOverwrite'
        },
        { text: '', value: 'edit' }
      ]
    }
  },

  watch: {
    getStatusUpdateFacilitySaleItem () {
      this.$apollo.queries.saleItemList.refetch()
    }
  },

  components: {
    BaseFacility,
    CreateNewSaleItem
  }
}
</script>

<style lang="scss" scoped>
.v-card__title{
  color: #000 !important;
  font-weight: bold !important;
}
::v-deep{
  .v-data-table-header{
    tr{
      th{
        font-weight: 500 !important;
      }
    }
  }
  tbody{
    tr{
      td{
        font-weight: 500 !important;
      }
    }
  }
  .v-btn--outlined{
    height: 28px !important;
    width: 40px !important;
    min-width: 40px !important;
    .v-btn__content{
      font-size: 12px !important;
      font-weight: 600 !important;
    }
  }
}
</style>
