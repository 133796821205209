var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseFacility',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',{staticClass:"py-4 page-title-main"},[_vm._v(" "+_vm._s(_vm.$t("routeTitles.facility.facility"))+" ")])]},proxy:true},{key:"default",fn:function(){return [_c('v-card',{staticClass:"pa-5 mt-10"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("facility.productsForSale.listOfItemForSale"))+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"table-custom",attrs:{"headers":_vm.header,"items":_vm.saleItemList,"hide-default-footer":"","disable-sort":"","disable-pagination":"","no-data-text":_vm.$t('rules.noData')},scopedSlots:_vm._u([{key:"item.allowPriceOverwrite",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.allowPriceOverwrite ? "変更可能" : "変更不可")+" ")]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taxType === 'Normal' ? '消費税 10%' : item.taxType === 'Lowered' ? '軽減税率 8%' : '不課税')+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }))+" "+_vm._s(item.priceIncludeTax ? '（税込）' : '（税抜）')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.checkPerUser,"outlined":"","color":"var(--bt__blue)"},on:{"click":function($event){_vm.setSaleItem(item);
                _vm.isShowEdit = true;}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.edit"))+" ")])]}}],null,true)}),_c('v-row',{staticClass:"mt-10 d-flex justify-end pb-8"},[_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.checkPerUser,"color":"var(--bt__blue)"},on:{"click":function($event){return _vm.createNewItem()}}},[_vm._v(" "+_vm._s(_vm.$t("facility.productsForSale.createNewSaleItem"))+" ")])],1)],1)],1),_c('CreateNewSaleItem',{key:_vm.idx,attrs:{"visible":_vm.isShowCreateNew,"title":_vm.$t('facility.productsForSale.createNewSaleItem'),"is-edit":false},on:{"close":function($event){_vm.isShowCreateNew = false}}}),_c('CreateNewSaleItem',{attrs:{"visible":_vm.isShowEdit,"title":_vm.$t('facility.productsForSale.editSaleItem'),"is-edit":true},on:{"close":function($event){_vm.isShowEdit = false}}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }